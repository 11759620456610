// src/components/CategoryGrid.jsx
import { navigate } from "gatsby";
import React, { FC, useEffect, useState } from "react";
import { ProductCategory } from "../types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { generateUrl } from "../utils/helper";
import { utmParams } from "../utils/Constants/utcParams";
import Link from "./Link";
import { useLocation } from "@reach/router";
import { RightArrow } from "./common/Svg";
// interface image {
//   src: string;
//   localFile: {
//     url: any;
//   };
// }
// interface Category {
//   name: string;
//   title: string;
//   id: string;
//   description: string;
//   image: image;
// }

interface Props {
  categoryList: ProductCategory[];
  homePageInfo: any;
}


const CategoryGrid: FC<Props> = ({ categoryList, homePageInfo }) => {
  const location = useLocation();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  return (
    <div className="flex md:flex-row flex-col justify-between mx-auto contain px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] md:py-[70px] py-[50px] border-b-[14px] border-[#F6F6F6] relative fadeInUp-animation">
      <div className="md:w-[40%] w-full home-cat-text">
        <div className=" md:sticky relative md:top-[100px] w-full">
          <div
            dangerouslySetInnerHTML={{ __html: homePageInfo.description }}
          ></div>

          <span className="text-primary flex items-center gap-2 font-sofiaBold font-bold  mb-8 text-[18px]">
            {homePageInfo.buttonText}
            {isMounted ? <span><RightArrow /></span> : <></>}
          </span>

        </div>
      </div>
      <div className="md:w-[60%] w-full md:mt-0 mt-[30px] lg:pl-5">
        <div className="grid grid-cols-1  lg:grid-cols-2  gap-x-3 gap-y-8 w-full">
          {categoryList.map((category, index) => (
            <div
              key={category.id}
              className="cat-side relative cursor-pointer md:py-4 py-2 md:pl-6 md:pr-3 pl-4 pr-3  flex flex-col items-start justify-between"
              onClick={() => navigate(`/product-category/${category.slug}` + location.search)}
            >
              <div className="flex w-full justify-between items-center gap-2">
                {/* <Link className="xl:text-[30px] lg:text-[22px] text-[20px]  font-sofiaSemibold font-semiBold leading-[1.2] w-[50%]"
                  to={`/product-category/${category.slug}`}>  {category.name.replace('&amp;',"&")}</Link> */}
                <Link preserveQueryParams={true} className="xl:text-[30px] lg:text-[22px] text-[20px]  font-sofiaSemibold font-semiBold leading-[1.2] w-[50%]"
                  to={`/product-category/${category.slug}`}>{category.name.replace('&amp;', "&")}</Link>

                <div className="xl:w-[160px] lg:w-[130px] h-[82px] rounded-[21px]">
                  <LazyLoadImage
                    effect="blur"
                    loading="lazy"
                    src={
                      category.image?.localFile?.url
                        ? category.image?.localFile?.url
                        : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-land.png`
                    }
                    alt={category.name.replace('&amp;', "&")}
                    className=" object-cover rounded-[21px] xl:w-[160px] w-[130px] h-[82px]"
                  />

                </div>
              </div>
              {/* <p className="font-sofiaRegular font-regular text-[#6C6C6C] text-[14px]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p> */}
              {/*<p className="xl:text-[18px] md:text-[16px] text-[15px] font-sofiaRegular font-regular text text-[#6C6C6C] ">{category.description.substring(100, 0)}</p>*/}
              <p
                className="xl:text-[18px] md:text-[15px] mt-[10px] text-[14px] font-sofiaRegular font-regular text text-[#6C6C6C]"
                dangerouslySetInnerHTML={{
                  __html: category.description.substring(100, 0),
                }}
              ></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryGrid;
