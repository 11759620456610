// src/components/ReviewCard.tsx
import React from 'react';

interface ReviewCardProps {
  name: string;
  location: string;
  title: string;
  review: string;
  rating: number;
  img: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ name, location, title, review, rating, img }) => {
  return (
    // <div className="py-6 lg:border-r  w-full">

    <div className="py-6 review-card  w-full">
      <div className="mb-4">
        <h3 className="text-[20px] !normal-case font-sofiaMedium font-medium">{title}</h3>
        <div className="flex items-center mt-2 ml-[-4px]">
          {[...Array(5)].map((_, i) => (
            <svg
              key={i}
              className={`h-5 w-5 fill-current ${i < rating ? 'text-yellow-500' : 'text-gray-300'}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M9.049 2.927C9.271 2.36 9.729 2 10.293 2c.564 0 1.023.36 1.245.927l1.05 2.676c.144.366.505.621.897.621h2.918c.7 0 1.07.904.553 1.354l-2.361 1.957a1 1 0 00-.364 1.118l1.05 2.676c.272.694-.218 1.451-.926 1.451-.282 0-.556-.105-.77-.295L10 12.347l-2.361 1.957a1 1 0 01-.77.295c-.708 0-1.198-.757-.926-1.451l1.05-2.676a1 1 0 00-.364-1.118L4.34 7.578c-.516-.45-.147-1.354.553-1.354h2.918c.392 0 .753-.255.897-.621l1.05-2.676z" />
            </svg>
          ))}
        </div>
      </div>
      <p className=" text-[#6C6C6C] font-sofiaRegular font-regular text-[16px] mb-4">{review.replace("&#x27;", "\'")}</p>
      <div className="flex items-center">
        {/* <img src={img} alt={name} className="w-12 h-12 rounded-full mr-3" /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="w-12 h-12 rounded-full mr-3"
        >
          <circle cx="12" cy="12" r="10" fill="#F1EFEA" /> {/* Background Circle */}
          <circle cx="12" cy="8" r="4" fill="#C8C6C1" />  {/* Head */}
          <path
            d="M12 14c-4 0-6 2-6 2s0 2 6 2 6-2 6-2-2-2-6-2z"
            fill="#C8C6C1"
          />
        </svg>
        <div>
          <p className=" font-sofiaBold font-bold text-black ">{name}</p>
          {/* <p className="text-[#6C6C6C] text-[15px] font-sofiaRegular font-regular">{location}</p> */}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
