import React, { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useStaticQuery, graphql } from "gatsby";
import YtLogo from "../../../assets/images/youtube.png";
import { SearchMini } from "../Svg";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { getQueryString } from "../../../utils/helper";
import useQueryString from "../../../utils/Custom-hook/useQueryString";

interface VideoInfo {
  title: string;
  id?: number;
  videoInfor: {
    videoUrl: {
      target: any;
      title: string;
      url: any;
    };
  };
}

interface Props {
  SectionTitle: string;
  platform?: string;
  videos: VideoInfo[];
}

const VideoPlayer: FC<Props> = ({ SectionTitle, platform, videos }) => {
  const [loadVideo, setLoadVideo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoadVideo(true);
    }, 3500);
  }, []);

  return (
    <section className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] mx-auto lg:pb-[80px] pb-[50px] mb-30px contain   ">
      <div className=" border-b border-[#E4E4E4] lg:pb-[80px] pb-[30px]">
        <h2 className="xl:text-[65px] lg:text-[55px] text-[32px] font-sofiaMedium font-medium  md:text-[55px]  items-center text-start gap-2 mb-8 lg:flex inline-block ">
          <span>{SectionTitle}</span>
          {platform ? (
            <span className="md:w-[120px] w-[92px] lg:relative absolute mt-[6px] ml-[10px]">
              {" "}
              <img
                src={YtLogo}
                alt="Video link"
                width={120}
                height={50}
                loading="lazy"
                className="md:w-[120px] w-[92px] object-contain"
              />
            </span>
          ) : (
            <></>
          )}
        </h2>
        <div className="w-full lg:overflow-hidden video-sec overflow-auto relative md:mb-0 mb-[20px]">
          <div className="w-full flex gap-8 justify-between items-center ">
            {videos.map((video: any) => {
              return (
                <div
                  key={video.node.id}
                  className="relative  w-full  flex flex-col justify-center "
                >
                  {loadVideo ? (
                    <div className="lg:w-[100%] w-[319px]">
                      <LiteYouTubeEmbed
                        id={
                          getQueryString(
                            "v",
                            video.node.videoInfor.videoUrl.url
                          ) as string
                        }
                        title={video.node.title}
                        webp={true}
                        cookie={true}
                      />
                      <p className="text-left whitespace-nowrap font-sofiaSemibold font-semiBold xl:text-[25px] text-[20px]  mt-6 pb-[20px]">
                        {video.node.title}
                      </p>
                    </div>
                  ) : <></>}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoPlayer;
